define("console/components/pos-cart/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LENSES = {
    glasses: ['no_prescription', 'single_vision_lenses', 'progressive_lenses', 'degressive_lenses'],
    sunglasses: ['no_prescription', 'single_vision_lenses', 'progressive_lenses'],
    clipOns: []
  };
  var FILTERS = {
    glasses: ['blue_light_filter', 'light_responsive_filter', 'high_index'],
    sunglasses: ['polarizing_filter', 'high_index'],
    clipOns: ['polarizing_filter']
  };

  var _default = Ember.Component.extend({
    lensColors: Ember.computed.readOnly('item.product.category.lensColors'),
    defaultLensColor: Ember.computed.readOnly('item.product.lensColor'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var category = this.get('item.product.categorySlug');
      var surcharges = this.get('item.surcharges');
      this.set('lensOptions', LENSES[category.camelize()] || []);
      this.set('filterOptions', FILTERS[category.camelize()] || []);
      this.set('lens', this.lensOptions.find(function (lens) {
        return surcharges.includes(lens);
      }));
      this.filterOptions.forEach(function (filter) {
        _this.set(filter.camelize(), surcharges.includes(filter));
      });
    },
    saveSurcharges: function saveSurcharges() {
      var _this2 = this;

      var surcharges = [this.lens];
      this.filterOptions.forEach(function (filter) {
        surcharges.push(_this2.get(filter.camelize()) && filter);
      });
      this.item.set('surcharges', surcharges);

      if (this.item.hasLightResponsiveFilter && !this.item.lensColor) {
        this.item.set('lensColor', this.lensColors[0]);
      }

      this.updateItem(this.item);
    },
    actions: {
      updateLens: function updateLens(value) {
        this.set('lens', value);
        this.saveSurcharges();
      },
      updateFilter: function updateFilter(filter, value) {
        this.set(filter.camelize(), value);
        this.saveSurcharges();
      },
      cycleLensColor: function cycleLensColor() {
        var lensColor = this.item.lensColor || this.defaultLensColor;
        var i = this.lensColors.indexOf(lensColor);
        this.item.set('lensColor', this.lensColors[i + 1] || this.lensColors[0]);
        this.updateItem(this.item);
      }
    }
  });

  _exports.default = _default;
});